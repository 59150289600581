import React from 'react'
import { css } from '@emotion/core'
import useSmoothScroll from '../hooks/useSmoothScroll'

const indexCss = css`
  position: fixed;
  z-index: 100;
  left: var(--space-smaller);
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    position: relative;
    margin-bottom: 0.4rem;
    padding-left: 1rem;
    pointer-events: all;
    cursor: pointer;
    font-size: var(--font-size-smaller);
    transform-origin: center left;
    transition: all 250ms ease;
    :before {
      content: '';
      position: absolute;
      left: 0;
      height: 1px;
      top: calc(50% - 1px);
      width: 0.7rem;
      background-color: currentColor;
      transform: all 250ms ease;
    }
    :hover,
    &.inview {
      font-weight: 500;
      padding-left: 1.5rem;
      transform: scale(1.2);
      :before {
        width: 1.2rem;
      }
    }
  }
`

const SideIndex = ({ inViewIds, offsets, titles }) => {
  const scrollTo = useSmoothScroll()
  return (
    <div className="l-hide" css={indexCss}>
      {titles.map(({ id, title }) => (
        <div
          key={id}
          className={inViewIds.indexOf(id) === 0 ? 'inview' : ''}
          onClick={() => scrollTo(offsets.current[id])}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      ))}
    </div>
  )
}

export default SideIndex
