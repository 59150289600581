import { useState, useCallback, useRef } from 'react'

export default function useInView() {
  const [inViewIds, setInViewIds] = useState([])
  const offsets = useRef({})

  const handleInView = useCallback((id, inView, entry) => {
    offsets.current[id] =
      entry.boundingClientRect.y + window.scrollY - window.innerHeight / 10
    setInViewIds(ids => {
      if (inView) return ids.includes(id) ? ids : [id, ...ids]
      else return ids.filter(i => i !== id)
    })
  }, [])

  return { inViewIds, handleInView, offsets }
}
