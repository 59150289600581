import React, { useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import Block from '../components/blocks'
import useInView from '../hooks/useInView'
import SideIndex from '../components/SideIndex'
import { ButtonLink } from '../components/Buttons'

const ScrollBlock = React.memo(
  ({ id, title, hideTitle, content, onChange, link, linkTitle }) => {
    const _onChange = useCallback(
      (inView, entry) => onChange(id, inView, entry),
      [id, onChange]
    )

    return (
      <InView key={id} onChange={_onChange}>
        {!hideTitle && (
          <h2 className="c-small" dangerouslySetInnerHTML={{ __html: title }} />
        )}
        {content.map(({ _modelApiKey, __typename, id, ...rest }) => (
          <Block key={id} type={_modelApiKey} {...rest} />
        ))}
        {link && (
          <div
            className="c-small"
            style={{
              marginTop: 'calc(-1 * var(--space-largest))',
              marginBottom: 'var(--space-largest)'
            }}
          >
            <ButtonLink to={link.slug}>{linkTitle}</ButtonLink>
          </div>
        )}
      </InView>
    )
  }
)

const BioPage = ({
  data: {
    dato: { page }
  }
}) => {
  const { title, bioBlocks } = page

  const titles = useMemo(
    () => bioBlocks.map(({ blockLink: { id, title } }) => ({ id, title })),
    [bioBlocks]
  )

  const { offsets, handleInView, inViewIds } = useInView()

  return (
    <>
      <SideIndex titles={titles} offsets={offsets} inViewIds={inViewIds} />
      <h1 className="c-large">{title}</h1>
      <div className="separator c-small" style={{ borderWidth: 3 }} />
      {bioBlocks.map(({ blockLink: { id, ...rest } }) => (
        <ScrollBlock key={id} id={id} {...rest} onChange={handleInView} />
      ))}
    </>
  )
}

export default BioPage

export const query = graphql`
  query($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      page: pageBio(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
        bioBlocks {
          blockLink {
            id
            title
            hideTitle
            linkTitle
            link {
              ...LinkFragment
            }
            content {
              ... on DATO_TitleRecord {
                ...TitleRecordFragment
              }
              ... on DATO_ParagraphRecord {
                ...ParagraphRecordFragment
              }
              ... on DATO_QuoteRecord {
                ...QuoteRecordFragment
              }
              ... on DATO_ImageGridRecord {
                ...ImageGridRecordFragment
              }
              ... on DATO_GalleryRecord {
                ...GalleryRecordFragment
              }
              ... on DATO_ContentVideoRecord {
                ...VideoRecordFragment
              }
            }
          }
        }
      }
    }
  }
`
